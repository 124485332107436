/**
 * color definitions
 */
.djs-parent {
  --color-grey-225-10-15: hsl(225, 10%, 15%);
  --color-grey-225-10-35: hsl(225, 10%, 35%);
  --color-grey-225-10-55: hsl(225, 10%, 55%);
  --color-grey-225-10-75: hsl(225, 10%, 75%);
  --color-grey-225-10-80: hsl(225, 10%, 80%);
  --color-grey-225-10-85: hsl(225, 10%, 85%);
  --color-grey-225-10-90: hsl(225, 10%, 90%);
  --color-grey-225-10-95: hsl(225, 10%, 95%);
  --color-grey-225-10-97: hsl(225, 10%, 97%);

  --color-blue-205-100-45: hsl(205, 100%, 45%);
  --color-blue-205-100-45-opacity-30: hsla(205, 100%, 45%, 30%);
  --color-blue-205-100-50: hsl(205, 100%, 50%);
  --color-blue-205-100-50-opacity-15: hsla(205, 100%, 50%, 15%);
  --color-blue-205-100-70: hsl(205, 100%, 75%);
  --color-blue-205-100-95: hsl(205, 100%, 95%);

  --color-green-150-86-44: hsl(150, 86%, 44%);

  --color-red-360-100-40: hsl(360, 100%, 40%);
  --color-red-360-100-45: hsl(360, 100%, 45%);
  --color-red-360-100-92: hsl(360, 100%, 92%);
  --color-red-360-100-97: hsl(360, 100%, 97%);

  --color-white: hsl(0, 0%, 100%);
  --color-black: hsl(0, 0%, 0%);
  --color-black-opacity-10: hsla(0, 0%, 0%, 10%);
  --color-black-opacity-30: hsla(0, 0%, 0%, 30%);

  --canvas-fill-color: var(--color-white);

  --bendpoint-fill-color: var(--color-blue-205-100-45);
  --bendpoint-stroke-color: var(--canvas-fill-color);

  --context-pad-entry-background-color: var(--color-white);
  --context-pad-entry-hover-background-color: var(--color-grey-225-10-95);

  --element-dragger-color: var(--color-blue-205-100-50);
  --element-hover-outline-fill-color: var(--color-blue-205-100-45);
  --element-selected-outline-stroke-color: var(--color-blue-205-100-50);
  --element-selected-outline-secondary-stroke-color: var(--color-blue-205-100-70);

  --lasso-fill-color: var(--color-blue-205-100-50-opacity-15);
  --lasso-stroke-color: var(--element-selected-outline-stroke-color);

  --palette-entry-color: var(--color-grey-225-10-15);
  --palette-entry-hover-color: var(--color-blue-205-100-45);
  --palette-entry-selected-color: var(--color-blue-205-100-50);
  --palette-separator-color: var(--color-grey-225-10-75);
  --palette-toggle-hover-background-color: var(--color-grey-225-10-55);
  --palette-background-color: var(--color-grey-225-10-97);
  --palette-border-color: var(--color-grey-225-10-75);

  --popup-font-family: "IBM Plex Sans", sans-serif;
  --popup-font-size: 14px;
  --popup-header-entry-selected-color: var(--color-blue-205-100-50);
  --popup-header-font-weight: bolder;
  --popup-header-group-divider-color: var(--color-grey-225-10-75);
  --popup-background-color: var(--color-white);
  --popup-border-color: transparent;
  --popup-shadow-color: var(--color-black-opacity-30);
  --popup-description-color: var(--color-grey-225-10-55);
  --popup-no-results-color: var(--color-grey-225-10-55);
  --popup-entry-title-color: var(--color-grey-225-10-55);
  --popup-entry-hover-color:  var(--color-grey-225-10-95);
  --popup-search-border-color: var(--color-grey-225-10-75);
  --popup-search-focus-border-color: var(--color-blue-205-100-50);
  --popup-search-focus-background-color: var(--color-blue-205-100-95);

  --resizer-fill-color: var(--color-blue-205-100-45);
  --resizer-stroke-color: var(--canvas-fill-color);

  --search-font-family: "IBM Plex Sans", sans-serif;
  --search-font-size: 14px;
  --search-container-background-color: var(--color-white);
  --search-shadow-color: var(--color-black-opacity-30);
  --search-input-border-color: var(--color-grey-225-10-75);
  --search-input-focus-border-color: var(--color-blue-205-100-50);
  --search-input-focus-background-color: var(--color-blue-205-100-95);
  --search-result-hover-background-color: var(--color-grey-225-10-95);
  --search-result-secondary-color: var(--color-grey-225-10-55);
  --search-preselected-background-color: var(--color-blue-205-100-50-opacity-15);

  --shape-attach-allowed-stroke-color: var(--color-blue-205-100-50);
  --shape-connect-allowed-fill-color: var(--color-grey-225-10-97);
  --shape-drop-allowed-fill-color: var(--color-grey-225-10-97);
  --shape-drop-not-allowed-fill-color: var(--color-red-360-100-97);
  --shape-resize-preview-stroke-color: var(--color-blue-205-100-50);

  --snap-line-stroke-color: var(--color-blue-205-100-45-opacity-30);

  --space-tool-crosshair-stroke-color: var(--color-black);

  --tooltip-error-background-color: var(--color-red-360-100-97);
  --tooltip-error-border-color: var(--color-red-360-100-45);
  --tooltip-error-color: var(--color-red-360-100-45);
}

/**
 * SVG styles
 */

.djs-container svg.drop-not-ok {
  background: var(--shape-drop-not-allowed-fill-color) !important;
}

.djs-container svg.new-parent {
  background: var(--shape-drop-allowed-fill-color) !important;
}

/**
 * outline styles
 */

.djs-outline,
.djs-selection-outline {
  fill: none;
  shape-rendering: geometricPrecision;
  stroke-width: 2px;
}

.djs-outline {
  visibility: hidden;
}

.djs-selection-outline {
  stroke: var(--element-selected-outline-stroke-color);
}

.djs-element.selected .djs-outline {
  visibility: visible;

  stroke: var(--element-selected-outline-stroke-color);
}

.djs-connection.selected .djs-outline {
  display: none;
}

.djs-multi-select .djs-element.selected .djs-outline {
  stroke: var(--element-selected-outline-secondary-stroke-color);
  display: block;
}

.djs-shape.connect-ok .djs-visual > :nth-child(1) {
  fill: var(--shape-connect-allowed-fill-color) !important;
}

.djs-shape.connect-not-ok .djs-visual > :nth-child(1),
.djs-shape.drop-not-ok .djs-visual > :nth-child(1) {
  fill: var(--shape-drop-not-allowed-fill-color) !important;
}

.djs-shape.new-parent .djs-visual > :nth-child(1) {
  fill: var(--shape-drop-allowed-fill-color) !important;
}


/* Override move cursor during drop and connect */
.drop-not-ok,
.connect-not-ok,
.drop-not-ok *,
.connect-not-ok * {
  cursor: not-allowed !important;
}

.drop-ok,
.connect-ok,
.drop-ok *,
.connect-ok * {
  cursor: default !important;
}

.djs-element.attach-ok .djs-visual > :nth-child(1) {
  stroke-width: 5px !important;
  stroke: var(--shape-attach-allowed-stroke-color) !important;
}

.djs-frame.connect-not-ok .djs-visual > :nth-child(1),
.djs-frame.drop-not-ok .djs-visual > :nth-child(1) {
  stroke-width: 3px !important;
  stroke: var(--shape-drop-not-allowed-fill-color) !important;
  fill: none !important;
}

/**
* Selection box style
*
*/
.djs-lasso-overlay {
  fill: var(--lasso-fill-color);
  stroke: var(--lasso-stroke-color);
  stroke-width: 2px;
  shape-rendering: geometricPrecision;
  pointer-events: none;
}

/**
 * Resize styles
 */
.djs-resize-overlay {
  fill: none;

  stroke-dasharray: 5 1 3 1;
  stroke: var(--shape-resize-preview-stroke-color);

  pointer-events: none;
}

.djs-resizer-hit {
  fill: none;
  pointer-events: all;
}

.djs-resizer-visual {
  fill: var(--resizer-fill-color);
  stroke-width: 1px;
  stroke: var(--resizer-stroke-color);
  shape-rendering: geometricPrecision;
}

.djs-resizer:hover .djs-resizer-visual {
  stroke: var(--resizer-stroke-color);
  stroke-opacity: 1;
}

.djs-cursor-resize-ns,
.djs-resizer-n,
.djs-resizer-s {
  cursor: ns-resize;
}

.djs-cursor-resize-ew,
.djs-resizer-e,
.djs-resizer-w {
  cursor: ew-resize;
}

.djs-cursor-resize-nwse,
.djs-resizer-nw,
.djs-resizer-se {
  cursor: nwse-resize;
}

.djs-cursor-resize-nesw,
.djs-resizer-ne,
.djs-resizer-sw {
  cursor: nesw-resize;
}

.djs-shape.djs-resizing > .djs-outline {
  visibility: hidden !important;
}

.djs-shape.djs-resizing > .djs-resizer {
  visibility: hidden;
}

.djs-dragger > .djs-resizer {
  visibility: hidden;
}

/**
 * drag styles
 */
.djs-dragger * {
  fill: none !important;
  stroke: var(--element-dragger-color) !important;
}

.djs-dragger tspan,
.djs-dragger text {
  fill: var(--element-dragger-color) !important;
  stroke: none !important;
}

.djs-dragger marker circle,
.djs-dragger marker path,
.djs-dragger marker polygon,
.djs-dragger marker polyline,
.djs-dragger marker rect {
  fill: var(--element-dragger-color) !important;
  stroke: none !important;
}

.djs-dragger marker text,
.djs-dragger marker tspan {
  fill: none !important;
  stroke: var(--element-dragger-color) !important;
}

.djs-dragging,
.djs-dragging > * {
  opacity: 0.3 !important;
  pointer-events: none !important;
}

/**
 * no pointer events for visual
 */
.djs-visual,
.djs-outline {
  pointer-events: none;
}

.djs-element.attach-ok .djs-hit {
  stroke-width: 60px !important;
}

/**
 * all pointer events for hit shape
 */
.djs-element > .djs-hit-all,
.djs-element > .djs-hit-no-move {
  pointer-events: all;
}

.djs-element > .djs-hit-stroke,
.djs-element > .djs-hit-click-stroke {
  pointer-events: stroke;
}

/**
 * shape / connection basic styles
 */
.djs-connection .djs-visual {
  stroke-width: 2px;
  fill: none;
}

.djs-cursor-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.djs-cursor-grabbing {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.djs-cursor-crosshair {
  cursor: crosshair;
}

.djs-cursor-move {
  cursor: move;
}

.djs-cursor-resize-ns {
  cursor: ns-resize;
}

.djs-cursor-resize-ew {
  cursor: ew-resize;
}


/**
 * snapping
 */
.djs-snap-line {
  stroke: var(--snap-line-stroke-color);
  stroke-linecap: round;
  stroke-width: 2px;
  pointer-events: none;
}

/**
 * snapping
 */
.djs-crosshair {
  stroke: var(--space-tool-crosshair-stroke-color);
  stroke-linecap: round;
  stroke-width: 1px;
  pointer-events: none;
  shape-rendering: geometricPrecision;
  stroke-dasharray: 5, 5;
}

/**
 * palette
 */

.djs-palette {
  position: absolute;
  left: 20px;
  top: 20px;

  box-sizing: border-box;
  width: 48px;
}

.djs-palette .separator {
  margin: 5px;
  padding-top: 5px;

  border: none;
  border-bottom: solid 1px var(--palette-separator-color);

  clear: both;
}

.djs-palette .entry:before {
  vertical-align: initial;
}

.djs-palette .djs-palette-toggle {
  cursor: pointer;
}

.djs-palette .entry,
.djs-palette .djs-palette-toggle {
  color: var(--palette-entry-color);
  font-size: 30px;

  text-align: center;
}

.djs-palette .entry {
  float: left;
}

.djs-palette .entry img {
  max-width: 100%;
}

.djs-palette .djs-palette-entries:after {
  content: '';
  display: table;
  clear: both;
}

.djs-palette .djs-palette-toggle:hover {
  background: var(--palette-toggle-hover-background-color);
}

.djs-palette .entry:hover {
  color: var(--palette-entry-hover-color);
}

.djs-palette .highlighted-entry {
  color: var(--palette-entry-selected-color) !important;
}

.djs-palette .entry,
.djs-palette .djs-palette-toggle {
  width: 46px;
  height: 46px;
  line-height: 46px;
  cursor: default;
}

/**
 * Palette open / two-column layout is controlled via
 * classes on the palette. Events to hook into palette
 * changed life-cycle are available in addition.
 */
.djs-palette.two-column.open {
  width: 94px;
}

.djs-palette:not(.open) .djs-palette-entries {
  display: none;
}

.djs-palette:not(.open) {
  overflow: hidden;
}

.djs-palette.open .djs-palette-toggle {
  display: none;
}

/**
 * context-pad
 */
.djs-context-pad {
  position: absolute;
  display: none;
  pointer-events: none;
  line-height: 1;
  width: 72px;
  z-index: 100;
}

.djs-context-pad .entry {
  width: 22px;
  height: 22px;
  text-align: center;
  display: inline-block;
  font-size: 22px;
  margin: 0 2px 2px 0;

  border-radius: 3px;

  cursor: default;

  background-color: var(--context-pad-entry-background-color);
  box-shadow: 0 0 2px 1px var(--context-pad-entry-background-color);
  pointer-events: all;
  vertical-align: middle;
}

.djs-context-pad .entry:hover {
  background: var(--context-pad-entry-hover-background-color);
}

.djs-context-pad.open {
  display: block;
}

/**
 * popup styles
 */
.djs-popup {
  line-height: 1;
  box-sizing: border-box;
  width: min-content;
  background: var(--popup-background-color);
  overflow: hidden;
  position: fixed;
  z-index: 200;
  box-shadow: 0px 2px 6px var(--popup-shadow-color);
  border: solid 1px var(--popup-border-color);
  min-width: 120px;
  outline: none;
  font-size: var(--popup-font-size);
  font-family: var(--popup-font-family);
}

.djs-popup-search input {
  width: 100%;
  box-sizing: border-box;
  font-size: var(--popup-font-size);
  padding: 3px 6px 3px 28px;
  border-radius: 2px;
  border: solid 1px var(--popup-search-border-color);
  line-height: 21px;
}

.djs-popup-search input:focus {
  background-color: var(--popup-search-focus-background-color);
  border: solid 1px var(--popup-search-focus-border-color);
  outline: none;
}

.djs-popup-header {
  display: flex;
  align-items: stretch;
  line-height: 20px;
  margin: 10px 12px 10px 12px;
}

.djs-popup-header .entry {
  border-radius: 2px;
}

.djs-popup button.entry {
  padding: 0;
  background: transparent;
  border: 0;
}

.djs-popup-header .entry.active {
  color: var(--popup-header-entry-selected-color);
}

.djs-popup-header .entry.disabled {
  color: inherit;
}

.djs-popup-header-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.djs-popup-header-group .entry {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.djs-popup-header-group + .djs-popup-header-group:before {
  content: '';
  width: 1px;
  height: 20px;
  background: var(--popup-header-group-divider-color);
  margin: 0 5px;
}

.djs-popup-search {
  position: relative;
  width: auto;
  margin: 10px 12px;
}

.djs-popup-title {
  font-size: var(--popup-font-size);
  font-weight: var(--popup-header-font-weight);
  flex: 1;
  margin: 0;
}

.djs-popup-search-icon {
  position: absolute;
  left: 8px;
  top: 7px;
}

.djs-popup-results {
  margin: 7px 3px 7px 12px;
  list-style: none;
  max-height: 280px;
  overflow: auto;
  padding-right: 9px;
}

.djs-popup-group {
  margin: 0;
  padding: 0;
  width: 100%;
}

.djs-popup-body .entry,
.djs-popup-body .entry-header {
  padding: 5px 7px;
  cursor: default;
  border-radius: 4px;
}

.djs-popup-body .entry-header {
  font-weight: var(--popup-header-font-weight);
  color: var(--popup-entry-title-color);
  padding-left: 0;
}

.djs-popup [class*="icon"] .djs-popup-label,
.djs-popup-label:not(:first-child) {
  margin-left: .5em;
}

.djs-popup [class*="icon"]:before,
.djs-popup-entry-icon {
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.4em;
  vertical-align: middle;
}

.djs-popup-body .entry-header:not(:first-child) {
  margin-top: 8px;
  margin-bottom: 2px;
}

.djs-popup-body .entry {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: min-content;
}

.djs-popup .entry.selected {
  background-color: var(--popup-entry-hover-color);
}

.djs-popup-body .entry:not(:first-child) {
  margin-top: 2px;
}

.djs-popup-entry-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

.djs-popup-entry-description {
  color: var(--popup-description-color);
}

.djs-popup-label,
.djs-popup-entry-description {
  line-height: 1.4em;
}

.djs-popup .entry,
.djs-popup .entry-header {
  margin: 1px;
}

.djs-popup-title,
.djs-popup-label,
.djs-popup-entry-description,
.djs-popup .entry-header {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.djs-popup-entry-name {
  display: flex;
}

.djs-popup-body {
  flex-direction: column;
  width: auto;
}

.djs-popup *::-webkit-scrollbar {
  width: 6px;
}

.djs-popup *::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.2);
}

.djs-popup *::-webkit-scrollbar-track {
  box-shadow: none;
  background: transparent;
  margin: 0;
  padding: 5px;
}

.djs-popup-no-results {
  padding: 0 12px 12px 12px;
  color: var(--popup-no-results-color);
}

.djs-popup-entry-docs {
  flex: 0;
  flex-direction: row;
  align-items: center;
  padding-left: 5px;
  display: none;
}

.djs-popup-body .entry:hover .djs-popup-entry-docs {
  display: flex;
}

.djs-popup-entry-docs svg {
  vertical-align: middle;
  margin: auto 2px auto 5px;
}

/**
 *  palette styles
 */
.djs-palette {
  background: var(--palette-background-color);
  border: solid 1px var(--palette-border-color);
  border-radius: 2px;
}

/**
 * bendpoints
 */
.djs-segment-dragger,
.djs-bendpoint {
  display: none;
}

.djs-segment-dragger .djs-visual {
  display: none;

  fill: var(--bendpoint-fill-color);
  stroke: var(--bendpoint-stroke-color);
  stroke-width: 1px;
  stroke-opacity: 1;
}

.djs-segment-dragger:hover .djs-visual {
  display: block;
}

.djs-bendpoint .djs-visual {
  fill: var(--bendpoint-fill-color);
  stroke: var(--bendpoint-stroke-color);
  stroke-width: 1px;
}

.djs-segment-dragger:hover,
.djs-bendpoints.hover .djs-segment-dragger,
.djs-bendpoints.selected .djs-segment-dragger,
.djs-bendpoint:hover,
.djs-bendpoints.hover .djs-bendpoint,
.djs-bendpoints.selected .djs-bendpoint {
  display: block;
}

.djs-drag-active .djs-bendpoints * {
  display: none;
}

.djs-bendpoints:not(.hover) .floating {
  display: none;
}

.djs-segment-dragger:hover .djs-visual,
.djs-segment-dragger.djs-dragging .djs-visual,
.djs-bendpoint:hover .djs-visual,
.djs-bendpoint.floating .djs-visual {
  fill: var(--bendpoint-fill-color);
  stroke: var(--bendpoint-stroke-color);
  stroke-opacity: 1;
}

.djs-bendpoint.floating .djs-hit {
  pointer-events: none;
}

.djs-segment-dragger .djs-hit,
.djs-bendpoint .djs-hit {
  fill: none;
  pointer-events: all;
}

.djs-segment-dragger.horizontal .djs-hit {
  cursor: ns-resize;
}

.djs-segment-dragger.vertical .djs-hit {
  cursor: ew-resize;
}

.djs-segment-dragger.djs-dragging .djs-hit {
  pointer-events: none;
}

.djs-updating,
.djs-updating > * {
  pointer-events: none !important;
}

.djs-updating .djs-context-pad,
.djs-updating .djs-outline,
.djs-updating .djs-bendpoint,
.djs-multi-select .djs-bendpoint,
.djs-multi-select .djs-segment-dragger,
.connect-ok .djs-bendpoint,
.connect-not-ok .djs-bendpoint,
.drop-ok .djs-bendpoint,
.drop-not-ok .djs-bendpoint {
  display: none !important;
}

.djs-segment-dragger.djs-dragging,
.djs-bendpoint.djs-dragging {
  display: block;
  opacity: 1.0;
}


/**
 * tooltips
 */
.djs-tooltip-error {
  width: 160px;
  padding: 6px;

  background: var(--tooltip-error-background-color);
  border: solid 1px var(--tooltip-error-border-color);
  border-radius: 2px;
  color: var(--tooltip-error-color);
  font-size: 12px;
  line-height: 16px;

  opacity: 0.75;
}

.djs-tooltip-error:hover {
  opacity: 1;
}


/**
 * search pad
 */
.djs-search-open .djs-context-pad {
  display: none;
}

.djs-search-open .djs-connection.selected .djs-outline {
  display: block;
}

.djs-search-container {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  width: 25%;
  min-width: 300px;
  max-width: 400px;
  z-index: 10;

  font-family: var(--search-font-family);
  font-size: var(--search-font-size);
  border-radius: 2px;
  box-shadow: 0px 2px 6px var(--search-shadow-color);
}

.djs-search-container:not(.open) {
  display: none;
}

.djs-search-input {
  position: relative;
}

.djs-search-input svg {
  position: absolute;
  left: 8px;
  top: 7px;
}

.djs-search-input input {
  font-size: var(--search-font-size);
  width: 100%;
  padding: 3px 6px 3px 28px;
  border: 1px solid var(--search-input-border-color);
  border-radius: 2px;
  box-sizing: border-box;
  line-height: 21px;
}

.djs-search-input input:focus {
  background-color: var(--search-input-focus-background-color);
  border: solid 1px var(--search-input-focus-border-color);
  outline: none;
}

.djs-search-results {
  position: relative;
  overflow-y: auto;
  max-height: 200px;
  background: var(--search-container-background-color);
}

.djs-search-result {
  padding: 6px 8px;
}

.djs-search-result-primary {
  margin: 0 0 3px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.djs-search-result-secondary {
  font-family: monospace;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: var(--search-result-secondary-color);
}

.djs-search-result:hover {
  background: var(--search-result-hover-background-color);
}

.djs-search-result-selected {
  background: var(--search-result-hover-background-color);
}

.djs-search-result-selected:hover {
  background: var(--search-result-hover-background-color);
}

.djs-search-open .djs-element .djs-outline {
  fill: var(--search-preselected-background-color) !important;
}

/**
 * hidden styles
 */
.djs-element-hidden,
.djs-element-hidden .djs-hit,
.djs-element-hidden .djs-outline,
.djs-label-hidden .djs-label {
  display: none !important;
}

.djs-element .djs-hit-stroke,
.djs-element .djs-hit-click-stroke,
.djs-element .djs-hit-all {
  cursor: move;
}