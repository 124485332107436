/* You can add global styles to this file, and also import other style files */

// @tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

// Progress bar styles
// See: https://github.com/MurhafSousli/ngx-progressbar/blob/master/projects/ngx-progressbar/docs/Styling.md

:root {
  --ng-progress-thickness: 5;
  --ng-progress-color: #ff3d00;
}

.ng-progress-bar-wrapper {
  top: 64px !important;
}

// BPMN diagrams

.djs-container {
  .bpmn-execution {
    font-weight: 700;
    background: #1976d2;
    color: #ffffff;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    text-align: center;
    line-height: 24px;
  }

  .bpmn-incident {
    font-weight: 700;
    background: #d32f2f;
    color: #ffffff;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    text-align: center;
    line-height: 24px;
  }

  .bpmn-task {
    font-weight: 700;
    border-radius: 50%;
    height: 26px;
    width: 26px;
    text-align: center;
    line-height: 26px;
  }

  .bpmn-task-completed {
    background: #388e3c;
    color: #ffffff;
  }

  .bpmn-task-cancelled {
    background: #d32f2f;
    color: #ffffff;
  }

  .bpmn-icon {
    position: absolute;
    left: 1px;
    top: 1px;
  }
}
